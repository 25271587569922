/* Títulos */
h1 {
  color: rgb(249, 250, 250);
}

h2 {
  font-size: 16px; /* Aumentei um pouco o tamanho da fonte */
  color: rgb(13, 14, 14);
  margin: 8px 0; /* Um pouco mais de espaço para melhorar a leitura */
}

/* Aplicativos */
.div-app {
  height: 30px;  /* Melhorado o espaçamento para uma maior área visual */
  margin-top: 30px; 
  margin-bottom: 30px; /* Espaçamento uniforme em cima e embaixo */
}

/* Lista de aplicativos */
.div-list {
  display: flex;
  align-items: center; /* Melhor para centralizar alinhamento vertical */
  justify-content: flex-start; /* Alinha os itens à esquerda */
  flex-wrap: wrap; /* Ajusta os itens conforme necessário */
}

/* Emprego dos elementos na coluna */
.div-emp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Botão do aplicativo */

.box-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: rgba(255, 255, 255, 0.1); /* Fundo suave */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2); /* Contorno suave */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transições suaves */
  cursor: pointer;
}

.box-button:hover {
  transform: scale(1.05); /* Suaviza o aumento do botão */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2); /* Aumenta a sombra no hover */
}

.box-image {
  width: 80%; /* Ajuste a imagem para preencher 80% do botão */
  height: auto;
  object-fit: contain; /* Garante que a imagem não seja distorcida */
  transition: transform 0.3s ease; /* Transição suave para o efeito de escala */
}

.box-button:hover .box-image {
  transform: scale(1.1); /* Suaviza o aumento da imagem */
}

.box-text {
  font-size: 16px;
  font-weight: 600;
  color: #f9fafa; /* Cor de texto mais suave e neutra */
  margin-top: 10px;
  text-align: center;
  transition: color 0.3s ease;
}

.box-button:hover .box-text {
  color: #f9fafa; /* Mantém a cor do texto no hover */
}


/* Cartão do aplicativo */
.card-app {
  border-radius: 20px; /* Arredondamento mais suave */
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 6px 40px rgba(0, 0, 0, 0.2); /* Sombra mais forte para destaque */
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  text-align: left;
  margin: 30px 0;
  width: 800px;
  height: 220px; /* Aumentei um pouco a altura para um visual mais espaçoso */
  padding: 25px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease; /* Efeito de transformação suave */
  z-index: 1; /* Garantir que o card fique sobre o fundo, mas abaixo do sidebar */
}

.card-app:hover {
  transform: translateY(-8px); /* Levanta o cartão ao passar o mouse */
  box-shadow: 0 12px 60px rgba(0, 0, 0, 0.2); /* Efeito de sombra mais pronunciado */
}

/* Logo dentro do cartão */
.logo {
  width: 100px; /* Ajustei para um tamanho mais visível */
  height: auto;
  transition: transform 0.3s ease; /* Transição suave para transformação */
}

.card-app:hover .logo {
  transform: scale(1.05); /* Aumenta a logo ao passar o mouse */
}

/* Descrição do aplicativo */
.descricao {
  margin-left: 15px; /* Aumentei o espaçamento para maior clareza */
  color: rgb(100, 100, 100); /* Melhor contraste para legibilidade */
}

/* Nome do aplicativo */
.app-name {
  margin-top: 5px; 
  font-size: 1.1em; /* Aumentei o tamanho para maior destaque */
  font-weight: bold;
  color: rgb(20, 20, 20); /* Cor de destaque para o nome */
}

/* Responsividade */
@media (max-width: 1024px) {
  .card-app {
    width: 100%;
    height: auto;  /* A altura se ajusta conforme o conteúdo */
    padding: 20px;
  }
  
  .box-button {
    width: 140px;
    height: 140px;
  }

  .logo {
    width: 90px;
  }

  .descricao {
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 768px) {
  /* Ajustando para telas médias */
  .box-button {
    width: 130px;
    height: 130px;
  }

  .card-app {
    width: 100%;
    height: auto;
    flex-direction: column; /* Alinha o conteúdo verticalmente em telas pequenas */
  }

  .logo {
    width: 80px;
  }

  .descricao {
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .box-button {
    width: 120px;
    height: 120px;
  }

  .box-text {
    font-size: 14px;
  }

  .card-app {
    padding: 15px;
  }

  .logo {
    width: 70px;
  }
}

/* Para evitar a barra de rolagem */
body {
  overflow-x: hidden; /* Evita rolagem horizontal */
}

/* Ajustes para a sobreposição do sideNav */
.sideNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px; /* Ajuste conforme o tamanho do seu sidebar */
  height: 100vh;
  background-color: #333;
  z-index: 10; /* Garante que o sideNav fique acima do conteúdo */
}

/* Aplicando no conteúdo principal */
.main-content {
  margin-left: 250px; /* Deixe o conteúdo à direita do sideNav */
  padding: 20px;
  transition: margin-left 0.3s ease; /* Adiciona transição suave se o lado do Nav for alterado */
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0; /* Retira a margem quando o sidebar é colapsado ou em telas pequenas */
  }
}
